import { useTranslation } from 'react-i18next';
import type { DecoratedEntry } from '@/pages/Schedule/types';
import { Icon, IconButton, Tooltip } from '@/ui';

type Props = {
  entry: DecoratedEntry;
  isPending: boolean;
  onClick: () => void;
};

export const SuggestMessageButton = ({ entry, isPending, onClick }: Props) => {
  const { t } = useTranslation('entryMessageForm');
  const hasTitle = !!entry.title && entry.title.trim().length > 0;

  return (
    <Tooltip
      label={hasTitle ? t('what.ai_title') : t('what.ai_disabled_tooltip')}
      maxW="200px"
      textAlign="center"
    >
      <IconButton
        aria-label={t('what.ai_title')}
        colorScheme="dark"
        icon={<Icon icon="WandSparkles" size="14px" />}
        isDisabled={!hasTitle}
        isLoading={isPending}
        ml="auto"
        size="xs"
        variant="ghost"
        onClick={onClick}
      />
    </Tooltip>
  );
};
