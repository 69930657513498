import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  type MenuItemProps,
  MenuList,
} from '@/ui';

type Props = MenuItemProps & {
  isSelected: boolean;
  onDeleteClick?: () => void;
};

export const MemberItem = ({
  children,
  isSelected,
  onClick,
  onDeleteClick,
}: Props) => {
  const { t } = useTranslation('membersSelect');

  return (
    <Flex
      _hover={{ bg: 'gray.100' }}
      align="center"
      borderRadius="none"
      fontWeight="normal"
      gap="2"
      px="3"
      w="100%"
    >
      <Icon icon="Checkmark" visibility={isSelected ? 'visible' : 'hidden'} />
      <Button
        flex="1"
        fontWeight="normal"
        h="auto"
        py="2"
        textAlign="left"
        variant="unstyled"
        onClick={onClick}
      >
        {children}
      </Button>
      {onDeleteClick && (
        <Menu isLazy>
          <MenuButton
            as={IconButton}
            colorScheme="dark"
            icon={<Icon icon="MoreHorizontal" />}
            size="xs"
            variant="ghost"
            onClick={(event) => event.stopPropagation()}
          />
          <MenuList>
            <MenuItem icon={<Icon icon="Trash" />} onClick={onDeleteClick}>
              {t('delete_recipient')}
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
};
