import { ChevronDownIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@/hooks/useToast';
import { ConfirmDialog } from '@/pages/Schedule/components';
import { useRemoveMember, useScheduleMembers } from '@/pages/Schedule/hooks';
import {
  Box,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
} from '@/ui';
import { MemberItem } from './MemberItem';

type Member = ReturnType<typeof useScheduleMembers>['members'][number];

type Props = {
  selectedMemberIds: string[];
  isDisabled?: boolean;
  isAddMemberModalOpen?: boolean;
  sendToEveryone: boolean;
  onSelectMember: (member: Member) => void;
  onDeselectMember: (member: Member) => void;
  onSendToEveryoneChange: (checked: boolean) => void;
  onAddClick: () => void;
};

export const MembersSelect = ({
  selectedMemberIds,
  isDisabled,
  isAddMemberModalOpen,
  sendToEveryone,
  onSelectMember,
  onDeselectMember,
  onAddClick,
  onSendToEveryoneChange,
}: Props) => {
  const { t } = useTranslation('membersSelect');
  const toast = useToast();
  const { members } = useScheduleMembers();
  const { removeMember } = useRemoveMember();
  const [confirmMember, setConfirmMember] = useState<Member | null>(null);

  const handleDeleteConfirm = () => {
    if (confirmMember) {
      const { id, name } = confirmMember;
      onDeselectMember(confirmMember);

      removeMember(id, {
        onSuccess: () => {
          toast.notify(t('delete_toast.success', { name }));
          setConfirmMember(null);
        },
        onError: () => {
          toast.error(t('delete_toast.error', { name }));
        },
      });
    }
  };

  const selectedMembers =
    members?.filter(({ id }) => selectedMemberIds.includes(id)) ?? [];

  const selectedLabel = selectedMembers
    .map(({ name }) => name ?? '')
    .join(', ');

  return (
    <>
      <Menu
        // keep the menu open while members are being added
        autoSelect={false}
        closeOnBlur={!confirmMember}
        closeOnSelect={false}
        isLazy
        isOpen={isAddMemberModalOpen ? true : undefined}
        matchWidth
      >
        {({ onClose }) => (
          <>
            <MenuButton
              as={Button}
              fontWeight="normal"
              isDisabled={isDisabled}
              rightIcon={<ChevronDownIcon h={5} w={5} />}
              textAlign="left"
              variant="secondary"
              w="100%"
            >
              {sendToEveryone ? t('everyone') : selectedLabel}
            </MenuButton>
            <MenuList p="0" shadow="md" zIndex="var(--chakra-zIndices-modal)">
              <Box maxH="200px" overflowY="auto" py="2">
                <MemberItem
                  isSelected={sendToEveryone}
                  onClick={() => {
                    const nextValue = !sendToEveryone;
                    if (nextValue) {
                      onSendToEveryoneChange(nextValue);
                      onClose();
                    }
                  }}
                >
                  {t('everyone')}
                </MemberItem>
                {(members ?? []).map((member) => (
                  <MemberItem
                    isSelected={selectedMemberIds.includes(member.id)}
                    key={member.id}
                    onDeleteClick={() => setConfirmMember(member)}
                    onClick={() => {
                      if (selectedMemberIds.includes(member.id)) {
                        onDeselectMember(member);
                      } else {
                        onSelectMember(member);
                      }
                    }}
                  >
                    {member.name}
                  </MemberItem>
                ))}
              </Box>
              <MenuDivider m="0" />
              <MenuItem icon={<Icon icon="Plus" />} my="2" onClick={onAddClick}>
                {t('add_recipient')}
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
      <Portal appendToParentPortal={false}>
        <ConfirmDialog
          confirmButtonText={t('confirm_delete.confirm')}
          isOpen={!!confirmMember}
          title={t('confirm_delete.title', { name: confirmMember?.name })}
          onCancel={() => setConfirmMember(null)}
          onConfirm={handleDeleteConfirm}
        />
      </Portal>
    </>
  );
};
