import { useScheduleContext } from '../contexts';
import { useScheduleMembers } from './useScheduleMembers/useScheduleMembers';

export const useScheduleMembersLimit = () => {
  const { members, isSuccess } = useScheduleMembers();
  const { schedule } = useScheduleContext();

  if (!isSuccess || !schedule) {
    return undefined;
  }

  const limit = schedule.limits.memberCount;
  const count = members.length;

  return {
    members: count,
    limit,
    remaining: limit - count,
  };
};
